import { formatMoney } from "@api/money";
import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";

import BuyButton from "@components/BuyButton";
import LinkButton from "@components/LinkButton";

import { getProductRedirect, hasProductRedirect } from "../../lib/ProductRedirects";

const ProductCard: CFC<{ product: Product }> = ({ product, cy }) => {
  return (
    <>
      <div data-cy={cy} className="ProductCard">
        <img src={product.thumbnail.src} alt={product.title} />
        <div className="title">{product.title}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: product.description }} />
        <div className="actions">
          {!hasProductRedirect(product.mpn) && (
            <div className="price">
              <span className="price-value">{formatMoney(product.defaultVariant.price)}</span>
              {` `}
              <span className="price-description">{product.defaultVariant.description}</span>
            </div>
          )}
          <div className="buttons">
            {hasProductRedirect(product.mpn) ? (
              <LinkButton href={getProductRedirect(product.mpn)}>Buy Now</LinkButton>
            ) : (
              <BuyButton product={product} />
            )}
            <LinkButton href={`/course/id/${product.mpn}`} theme="secondary">
              Course Details
            </LinkButton>
          </div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .ProductCard {
            position: relative;
            padding-bottom: 75px;
            margin-bottom: 75px;
          }

          .ProductCard img {
            width: 100%;
            max-width: 100%;
          }

          .title {
            margin: 16px 0;
            font-family: "Bebas Neue", Regular;
            font-size: 30px;
            color: rgb(255, 54, 0);
          }

          .actions {
            position: absolute;
            bottom: 0;
            width: 100%;
          }

          .buttons {
            display: flex;
          }

          .actions-item {
            flex: 1;
          }

          .description {
            font-family: Lato, Regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
            line-height: 26px;
            color: rgb(51, 51, 51);
          }

          .price {
            margin: 0 0 15px 0;
          }

          .price-value {
            font-family: "Bebas Neue", Regular;
            color: rgb(236, 31, 0);
            font-size: 24px;
          }

          .price-description {
            font-family: "Bebas Neue", Regular;
            color: rgb(0, 0, 0);
            font-size: 18px;
          }
        `}
      </style>
    </>
  );
};

export default ProductCard;
