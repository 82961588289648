import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";
import { useRouter } from "next/router";
import { FC, useContext } from "react";

import Button from "@components/Button";
import { ButtonProps } from "@components/Button/Button";
import UserContext from "@components/UserContext";

type BuyButtonProps = ButtonProps & {
  product: Product;
};

const BuyButton: CFC<BuyButtonProps> = (props) => {
  const router = useRouter();
  const { addProduct } = useContext(UserContext);
  const { product, cy } = props;
  return (
    <>
      <Button
        cy={cy}
        {...props}
        onClick={async () => {
          await addProduct({
            productId: product.id,
            variantId: product.defaultVariant.id,
            quantity: 1,
          });
          await router.push("/cart");
        }}>
        Buy Now
      </Button>
    </>
  );
};

export default BuyButton;
