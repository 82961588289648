import { CFC } from "@api/types/cypress";
import { FC, MouseEventHandler, PropsWithChildren } from "react";

export type ButtonProps = {
  theme?: "primary" | "secondary";
  width?: string;
  disabled?: boolean;
};

type ButtonFinalProps = {
  onClick?: MouseEventHandler<HTMLElement>;
} & ButtonProps;

const Button: CFC<PropsWithChildren<ButtonFinalProps>> = ({
  theme = "primary",
  width = "100%",
  children,
  onClick = () => {},
  disabled = false,
  cy,
}) => {
  return (
    <>
      <div
        className={`Button ${disabled ? "disabled" : ""}`}
        onClick={(event) => {
          if (!disabled) {
            onClick(event);
          }
        }}
        data-cy={cy}>
        {children}
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .Button {
            display: flex;
            width: ${width};
            text-align: center;
            background-color: ${theme == "primary" ? "rgb(255, 54, 0)" : "rgb(45, 45, 45)"};
            color: white;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            padding: 16px 10px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
          }

          .Button:hover {
            background-color: ${theme == "primary" ? "rgb(236, 31, 0)" : "rgb(45, 45, 45)"};
          }

          .Button.disabled {
            background-color: #a8b6be;
            color: #e0e0e0;
            cursor: default;
          }
        `}
      </style>
    </>
  );
};

export default Button;
